<template>
  <div class="d-flex flex-column">
    <task-card :id="taskId"/>
    <discussion-list class="mt-7" entity="task" :entity-id="taskId"/>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white"
                @click="$router.push({name: 'showTask', params: {id: taskId}})">
        {{ $t('btn.back_to_task') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import TaskCard from './components/TaskCard';
import DiscussionList from '@/components/comments/DiscussionList';

export default {
  components: {
    TaskCard,
    DiscussionList
  },
  data() {
    return {
      taskId: null,
      ticketId: null
    }
  },
  beforeMount() {
    this.taskId = this.$route.params.id;
    this.ticketId = this.$route.params.ticketId;
  },
}
</script>